<template>
  <LandingPageLayout>
    <section class="white-section py-8 px-1 px-md-0">
      <div style="width: 100%; max-width: 800px; margin: auto">
        <div class="text-link">Contact Us</div>
        <h2 class="h2 mt-1">
          We make tech recruitment
          <span class="simple"> simple. </span>
        </h2>

        <p class="p font-20 mt-2" style="color: #344054">
          Interested in learning more about Infohob? Schedule a call today!
        </p>
      </div>
    </section>
    <section class="gray-section py-8 px-1 px-md-0">
      <div style="width: 100%; max-width: 600px; margin: auto">
        <Input
          type="text"
          class="col-12 mb-2"
          label-color="#101828"
          border-color="#eaecf0"
          placeholder="Your name"
          v-model="formData.name"
          name="Name"
          label="Name"
        />
        <Input
          type="email"
          class="col-12 mb-2"
          label-color="#101828"
          border-color="#eaecf0"
          placeholder="you@company.com"
          v-model="formData.email"
          name="Email"
          label="Email"
        />
        <div class="mb-2">
          <label class="fs-14 fw-500 d-block mb-1x">Phone Number</label>
          <VueTelInput
            class="tel__input"
            autoFormat
            defaultCountry="NG"
            v-model="formData.mobile_number"
          />
        </div>
        <div class="mb-2">
          <label class="fs-14 fw-500 d-block mb-1x"> How can we help? </label>
          <textarea
            style="border-color: #eaecf0"
            placeholder="Tell us a little about your query..."
            class="p-2 h-16"
          />
        </div>
        <Button theme="blue">Get in touch</Button>
      </div>
    </section>
    <DarkSection></DarkSection>
  </LandingPageLayout>
</template>

<script>
import LandingPageLayout from "@/layouts/LandingPageLayout";
import DarkSection from "./sections/DarkSection.vue";

export default {
  name: "LandingPage",
  components: {
    LandingPageLayout,

    DarkSection
  },
  data() {
    return {
      formData: {}
    };
  },

  computed: {},
  methods: {}
};
</script>

<style lang="scss"></style>
